<template>
  <b-container>
    <Heading type="h1" :title="trans('global.suppliers')" />
    <b-row>
      <b-col>
        <div class="alert alert-warning">
          <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('suppliers.no_suppliers') }}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Heading from '../../components/Core/Heading'

  export default {
    name: 'AllSuppliers',
    components: {
      Heading
    },
    beforeMount () {
      this.updateBreadcrumb([{ text: this.trans('global.suppliers'), to: '/suppliers' }, { text: this.trans('navigation.all_suppliers'), to: '#' }])
    }
  }
</script>
